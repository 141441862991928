var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-low-fare-tracking",
      "title": _vm.$t('reservation.lowFare.lowfareTracking'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-50 pb-0",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": function show($event) {
        return _vm.showModal();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "text-center w-100"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-secondary",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])], 1)];
      }
    }])
  }, [_c('div', [_c('b-card', {
    staticClass: "mb-75 border",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-1",
      "body-class": "mb-0 pb-50 px-75"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "m-0 text-black font-medium-2 font-weight-bolder"
        }, [_vm._v(" 1. " + _vm._s(_vm.$t('reservation.lowFare.selectTrip')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.hasTaskRunning,
      "opacity": "0.90",
      "rounded": "lg"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center fw-700 text-airline font-medium-2"
        }, [_vm._v(" Có hành trình đang thực hiện canh vé ... ")])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.listAllTrips, function (trip, tripIndex) {
    return _c('div', {
      key: tripIndex,
      staticClass: "mt-1"
    }, [_c('b-overlay', {
      class: _vm.arrIndexTripSuccess.includes(tripIndex) && !_vm.hasTaskRunning ? 'border-danger rounded-lg cursor-not-allowed' : '',
      attrs: {
        "show": _vm.arrIndexTripSuccess.includes(tripIndex) && !_vm.hasTaskRunning,
        "opacity": "0.78",
        "rounded": "lg"
      },
      scopedSlots: _vm._u([{
        key: "overlay",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-center fw-700 text-danger font-medium-2"
          }, [_vm._v(" Hành trình đã canh vé thành công ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      class: "cursor-pointer d-flex rounded-lg\n              ".concat(_vm.tripSelected.some(function (t) {
        return t.index === trip.index;
      }) ? 'border-success-2 font-weight-bolder' : 'border border-2'),
      on: {
        "click": function click($event) {
          return _vm.handleSelectedTrip(trip);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex-center"
    }, [_vm.tripSelected.some(function (t) {
      return t.index === trip.index;
    }) ? _c('feather-icon', {
      staticClass: "text-success mx-50",
      attrs: {
        "icon": 'CheckCircleIcon',
        "size": "22"
      }
    }) : _c('feather-icon', {
      staticClass: "text-secondary mx-50",
      attrs: {
        "icon": 'CircleIcon',
        "size": "22"
      }
    })], 1), _c('div', {
      staticClass: "d-flex-column"
    }, _vm._l(trip.tripInfo, function (segment, sIndex) {
      return _c('div', {
        key: sIndex,
        attrs: {
          "no-body": ""
        }
      }, [_c('div', {
        class: "px-25 px-md-1 py-25 font-weight-bolder\n                  ".concat(_vm.tripSelected.some(function (t) {
          return t.index === trip.index;
        }) ? 'text-body' : 'text-secondary', "\n                  ")
      }, [_c('div', {
        staticClass: "d-flex align-items-center"
      }, [trip.tripInfo.length > 1 ? _c('span', {
        class: "\n                      border-bottom mr-1 mr-md-2 text-nowrap\n                      ".concat(_vm.isMobileView ? 'font-small-3' : 'font-medium-1', "\n                      ")
      }, [_vm._v(" " + _vm._s("Ch\u1EB7ng ".concat(sIndex + 1, ": ")) + " ")]) : _vm._e(), segment.bookingClass || segment.groupClass ? _c('div', {
        staticClass: "w-100 font-weight-bolder d-flex align-items-center justify-content-between justify-content-sm-start"
      }, [_c('div', {
        class: "".concat(_vm.isMobileView ? 'font-small-3' : 'font-medium-1', " text-nowrap")
      }, [_c('span', [_vm._v(" " + _vm._s(segment.groupClass || _vm.getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName) + " ")]), segment.bookingClass && (segment.groupClass || _vm.getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName) ? _c('span', [_vm._v(" | ")]) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(segment.bookingClass) + " ")])])]) : _vm._e()]), _c('span', {
        class: "".concat(_vm.isMobileView ? 'font-small-4 font-weight-bolder' : 'font-medium-2 fw-700')
      }, [_vm._v(" " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(segment.airline, segment.flightNumber), " ").concat(_vm.convertISODateTime(segment.departureTime).dayMonth, " ").concat(segment.departure).concat(segment.arrival, " ").concat(_vm.convertISODateTime(segment.departureTime).hourMin, " ").concat(_vm.convertISODateTime(segment.arrivalTime).hourMin)) + " ")])])]);
    }), 0)])])], 1);
  }), 0)], 1), _c('b-card', {
    staticClass: "mb-75 border",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-1",
      "body-class": "mb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "m-0 text-black font-medium-2 font-weight-bolder",
          attrs: {
            "id": "reservation.lowFare.rangeFare"
          }
        }, [_vm._v(" 2. " + _vm._s(_vm.$t('reservation.lowFare.rangeFare')) + " ")])];
      },
      proxy: true
    }])
  }, [!_vm.isEmpty(_vm.listAllTrips) ? _c('div', [['fareWithMinMax'].includes(_vm.lowFareType) ? _c('div', {
    staticClass: "d-flex flex-column flex-lg-row align-items-lg-center justify-content-between mt-75"
  }, [_c('span', {
    staticClass: "text-nowrap mr-50",
    attrs: {
      "id": "reservation.lowFare.lowestFare"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.lowestFare')) + " ")]), _c('IAmInputMoney', {
    attrs: {
      "value-money": _vm.targetFareMin
    },
    on: {
      "update:valueMoney": function updateValueMoney($event) {
        _vm.targetFareMin = $event;
      },
      "update:value-money": function updateValueMoney($event) {
        _vm.targetFareMin = $event;
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column flex-lg-row align-items-lg-center justify-content-between mt-1"
  }, [_c('span', {
    staticClass: "text-nowrap mr-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.highestFare')) + " ("), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*" + _vm._s(_vm.$t('reservation.lowFare.required')))]), _vm._v("): ")]), _c('IAmInputMoney', {
    attrs: {
      "value-money": _vm.targetFareMax
    },
    on: {
      "update:valueMoney": function updateValueMoney($event) {
        _vm.targetFareMax = $event;
      },
      "update:value-money": function updateValueMoney($event) {
        _vm.targetFareMax = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "font-italic text-warning font-weight-bolder mt-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.noteGetPrice')) + " ")])]) : _vm._e()]), !_vm.isEmpty(_vm.listAllTrips) ? _c('b-card', {
    staticClass: "mb-75 border",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-1",
      "body-class": "py-1 mb-0 px-1 px-md-2"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "m-0 text-black font-medium-2 font-weight-bolder",
          attrs: {
            "id": "reservation.lowFare.deadlineTask"
          }
        }, [_vm._v(" 3. " + _vm._s(_vm.$t('reservation.lowFare.deadlineTask')) + " ")])];
      },
      proxy: true
    }], null, false, 2003746898)
  }, [_c('div', [_c('div', [_c('div', [_c('div', {
    staticClass: "button_spacing"
  }, _vm._l(_vm.optionsTimeSelect, function (time, indexTime) {
    return _c('div', {
      key: indexTime,
      staticClass: "time_select_class cursor-pointer py-50 px-75 px-md-2",
      class: _vm.timeSelected === time.value ? 'active' : '',
      attrs: {
        "variant": "light-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.handleSetDeadline(time.value);
        }
      }
    }, [_c('span', {
      staticClass: "font-weight-bolder text-nowrap"
    }, [_vm._v(" " + _vm._s(time.title) + " ")])]);
  }), 0), _vm.expectTime && !_vm.afterMaxDate ? _c('div', {
    staticClass: "mt-1 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.timeEndTracking')) + " "), _c('span', {
    staticClass: "text-warning text-nowrap tex-body ml-25"
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.deadline).timeDate.replace(" | ", " ")) + " ")])]) : _vm._e()]), _vm.afterMaxDate ? _c('div', {
    staticClass: "mt-1 text-normal"
  }, [_c('b-alert', {
    staticClass: "py-50 mt-2 mb-0 text-center",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.setTimeBefore')) + " " + _vm._s(_vm.convertISODateTime(_vm.maxDate).timeDate.replace(" | ", " ")) + " ")])], 1) : _vm._e()])])]) : _vm._e(), !_vm.isEmpty(_vm.listAllTrips) ? _c('b-card', {
    staticClass: "mb-75 border",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-1",
      "body-class": "py-1 mb-0 px-50 px-md-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "m-0 text-black font-medium-2 font-weight-bolder",
          attrs: {
            "id": "reservation.lowFare.lowfareType"
          }
        }, [_vm._v(" 4. " + _vm._s(_vm.$t('reservation.lowFare.lowfareType')) + " ")])];
      },
      proxy: true
    }], null, false, 2372879509)
  }, [_c('div', [_c('b-form-group', {
    attrs: {
      "label": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-radio-group', {
          attrs: {
            "id": "radio-group-bookingType",
            "stacked": "",
            "value-field": "value",
            "text-field": "name",
            "disabled": _vm.isDisableLowfareType,
            "options": _vm.bookingTypeOptions,
            "aria-describedby": ariaDescribedby
          },
          model: {
            value: _vm.bookingType,
            callback: function callback($$v) {
              _vm.bookingType = $$v;
            },
            expression: "bookingType"
          }
        })];
      }
    }], null, false, 2722706047)
  })], 1), _c('BAlert', {
    staticClass: "text-center fw-700 py-50 mb-50",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Trường hợp canh ra cùng hạng vé (ví dụ đặt HL/DS để canh HK) , hệ thống sẽ tự động sinh ra code mới. ")]), _c('BAlert', {
    staticClass: "text-center fw-700 py-50 mb-50",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" BGT không chịu trách nhiệm đối với các trường hợp bị hủy code do dupe. ")])], 1) : _vm._e(), !_vm.isEmpty(_vm.listAllTrips) ? _c('div', {
    staticClass: "text-center my-2"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-2",
    attrs: {
      "variant": "outline-info",
      "pill": "",
      "disabled": _vm.disableSetLowFareButton(_vm.tripSelected, _vm.lowFareType, _vm.targetFareMin, _vm.targetFareMax)
    },
    on: {
      "click": function click($event) {
        return _vm.handleLowFareTracking('set');
      }
    }
  }, [_c('span', {
    staticClass: "font-medium-1"
  }, [_vm._v(_vm._s(_vm.$t('reservation.lowFare.turnOn')))])])], 1) : _vm._e(), _vm.refbookingData.lowFareTrackingTasks ? _c('b-card', {
    staticClass: "mb-75 border",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-1",
      "body-class": "px-25 pb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "m-0 text-black font-medium-2 font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.infoLowFare')) + " ")])];
      },
      proxy: true
    }], null, false, 3171684126)
  }, [!_vm.isEmpty(_vm.refbookingData.lowFareTrackingTasks) ? _c('div', {
    staticClass: "mt-1 mt-md-0"
  }, [_c('b-table', {
    staticClass: "mt-1",
    attrs: {
      "outline": "",
      "responsive": "",
      "bordered": "",
      "small": "",
      "thead-class": "text-center text-dark font-weight-bolder text-nowrap",
      "tbody-class": "text-center",
      "items": _vm.getTrackingTaskItems(_vm.refbookingData.lowFareTrackingTasks),
      "fields": ['itinerary', 'information', 'result', 'action']
    },
    scopedSlots: _vm._u([_vm._l(['itinerary', 'information', 'result', 'action'], function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.label.toLowerCase()))) + " ")])];
        }
      };
    }), {
      key: "cell(itinerary)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return _vm._l(item.payload.segments, function (segment, indexSegment) {
          return _c('div', {
            key: indexSegment,
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(segment.airline, segment.flightNumber), " ").concat(_vm.convertISODateTime(segment.departureTime, 'UTC').dayMonth, " ").concat(segment.departure).concat(segment.arrival)) + " ")]), _c('div', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s("".concat(_vm.convertISODateTime(segment.departureTime, 'UTC').hourMin, " ").concat(_vm.convertISODateTime(segment.arrivalTime, 'UTC').hourMin)) + " ")])]);
        });
      }
    }, {
      key: "cell(information)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Giá thấp nhất: ")]), _c('div', {
          staticClass: "text-right font-weight-bolder mb-25"
        }, [_vm._v(" " + _vm._s(item.payload.minPrice ? _vm.formatCurrency(item.payload.minPrice) : 0) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Giá cao nhất: ")]), _c('div', {
          staticClass: "text-right font-weight-bolder"
        }, [_vm._v(" " + _vm._s(item.payload.maxPrice ? _vm.formatCurrency(item.payload.maxPrice) : 0) + " ")])])];
      }
    }, {
      key: "cell(result)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [!item.result ? _c('div', [['HOLD'].includes(_vm.bookingData.status) ? _c('b-badge', {
          staticClass: "badge-glow text-nowrap py-50 px-1 font-small-2",
          attrs: {
            "variant": item.payload.isExpired ? 'danger' : 'primary'
          }
        }, [_vm._v(" " + _vm._s(item.payload.isExpired ? _vm.$t('reservation.lowFare.expired') : _vm.$t('reservation.lowFare.processing')) + " ")]) : _vm._e(), item.payload.deadline ? _c('div', {
          staticClass: "text-danger font-italic mt-50"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.timeEndTracking')) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.payload.deadline).timeDate.replace(" | ", " ")) + " ")])]) : _vm._e()], 1) : _vm._e(), item.result ? _c('b-badge', {
          staticClass: "badge-glow text-nowrap py-50 font-small-2",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.success')) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [!item.result ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-50 px-md-1 text-nowrap",
          attrs: {
            "variant": "outline-danger rounded",
            "disabled": ['CANCEL'].includes(_vm.bookingData.status) || item.payload.isExpired
          },
          on: {
            "click": function click($event) {
              return _vm.handleLowFareTracking('cancel', item.id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.turnOff')) + " ")]) : _vm._e(), item.result ? _c('div', {
          staticClass: "w-100 text-center"
        }, _vm._l(JSON.parse(item.result).lowFareTrackedBookings, function (newItem, cIndex) {
          return _c('b-button', {
            key: cIndex,
            staticClass: "text-nowrap py-25 px-1 font-medium-1 rounded",
            class: JSON.parse(item.result).lowFareTrackedBookings.length > 1 ? 'mb-50' : '',
            attrs: {
              "variant": "outline-info"
            },
            on: {
              "click": function click($event) {
                return _vm.openLinkedBookingHandle(newItem.id);
              }
            }
          }, [_vm._v(" " + _vm._s(newItem.pnr) + " ")]);
        }), 1) : _vm._e()];
      }
    }], null, true)
  })], 1) : _c('b-alert', {
    staticClass: "px-1 py-50 mt-1",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('p', {
    staticClass: "font-medium-1 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.noItiSelect')) + " ")])])], 1) : _c('b-card', {
    staticClass: " border",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-1",
      "body-class": "mt-75 pb-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "m-0 text-black font-medium-2 font-weight-bolder"
        }, [_vm._v(" 5. " + _vm._s(_vm.$t('reservation.lowFare.infoLowFare')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('span', {
    staticClass: "text-warning font-medium-1 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.notTurnOn')) + " ")])])], 1), _c('b-alert', {
    staticClass: "px-1 py-50 my-50 font-medium-1",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('p', {
    staticClass: "text-airline fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.note')) + " ")]), _c('p', {
    staticClass: "text-danger fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.note1')) + " ")]), _c('p', {
    staticClass: "text-airline fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.note3')) + " ")])]), _c('ModalInfoTrip', {
    attrs: {
      "segment-info": _vm.segmentInfo
    },
    on: {
      "update:segmentInfo": function updateSegmentInfo($event) {
        _vm.segmentInfo = $event;
      },
      "update:segment-info": function updateSegmentInfo($event) {
        _vm.segmentInfo = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }