<template>
  <b-modal
    id="modal-modify-low-fare-tracking"
    :title="$t('reservation.lowFare.lowfareTracking')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    body-class="px-50 pb-0"
    centered
    no-close-on-backdrop
    size="lg"
    @show="showModal()"
  >
    <template #modal-footer="{close}">
      <div class="text-center w-100">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-secondary"
          pill
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
      </div>
    </template>

    <div>
      <!-- ANCHOR - Chọn hành trình -->
      <b-card
        header-bg-variant="light-warning"
        header-class="py-1"
        class="mb-75 border"
        body-class="mb-0 pb-50 px-75"
      >
        <template #header>
          <h5 class="m-0 text-black font-medium-2 font-weight-bolder">
            1. {{ $t('reservation.lowFare.selectTrip') }}
          </h5>
        </template>
        <b-overlay
          :show="hasTaskRunning"
          opacity="0.90"
          rounded="lg"
        >
          <template #overlay>
            <div class="text-center fw-700 text-airline font-medium-2">
              Có hành trình đang thực hiện canh vé ...
            </div>
          </template>

          <div
            v-for="(trip, tripIndex) of listAllTrips"
            :key="tripIndex"
            class="mt-1"
          >
            <b-overlay
              :show="arrIndexTripSuccess.includes(tripIndex) && !hasTaskRunning"
              opacity="0.78"
              rounded="lg"
              :class="arrIndexTripSuccess.includes(tripIndex) && !hasTaskRunning ? 'border-danger rounded-lg cursor-not-allowed' : ''"
            >
              <template #overlay>
                <div class="text-center fw-700 text-danger font-medium-2">
                  Hành trình đã canh vé thành công
                </div>
              </template>

              <div
                :class="`cursor-pointer d-flex rounded-lg
                ${tripSelected.some(t => t.index === trip.index) ? 'border-success-2 font-weight-bolder' : 'border border-2'}`"
                @click="handleSelectedTrip(trip)"
              >
                <!-- ANCHOR - ICON -->
                <div class="d-flex-center">
                  <feather-icon
                    v-if="tripSelected.some(t => t.index === trip.index)"
                    :icon="'CheckCircleIcon'"
                    class="text-success mx-50"
                    size="22"
                  />
                  <feather-icon
                    v-else
                    :icon="'CircleIcon'"
                    class="text-secondary mx-50"
                    size="22"
                  />
                </div>

                <!-- ANCHOR - thông tin hành trình -->
                <div class="d-flex-column">
                  <div
                    v-for="(segment, sIndex) of trip.tripInfo"
                    :key="sIndex"
                    no-body
                  >
                    <!-- ANCHOR - Thông tin chặng -->
                    <div
                      :class="`px-25 px-md-1 py-25 font-weight-bolder
                    ${tripSelected.some(t => t.index === trip.index) ? 'text-body' : 'text-secondary'}
                    `"
                    >
                      <div class="d-flex align-items-center">
                        <span
                          v-if="trip.tripInfo.length > 1"
                          :class="`
                        border-bottom mr-1 mr-md-2 text-nowrap
                        ${isMobileView ? 'font-small-3' : 'font-medium-1'}
                        `"
                        >
                          {{ `Chặng ${sIndex + 1}: ` }}
                        </span>

                        <!-- ANCHOR - Hạng vé -->
                        <div
                          v-if="segment.bookingClass || segment.groupClass"
                          class="w-100 font-weight-bolder d-flex align-items-center justify-content-between justify-content-sm-start"
                        >
                          <div :class="`${isMobileView ? 'font-small-3' : 'font-medium-1'} text-nowrap`">
                            <span>
                              {{ segment.groupClass || getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName }}
                            </span>
                            <span v-if="segment.bookingClass && (segment.groupClass || getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName)">
                              |
                            </span>
                            <span>
                              {{ segment.bookingClass }}
                            </span>
                          </div>

                          <!-- <b-button
                            v-if="false"
                            class="px-25 py-25 ml-50 px-md-1 d-block"
                            variant="flat-warning"
                            pill
                            size="sm"
                            @click.stop="handleShowModalInfoTrip(segment)"
                          >
                            <span
                              id="reservation.lowFare.flightStatus"
                              class="border-bottom-warning"
                            >{{ $t('reservation.lowFare.flightStatus') }}</span>
                          </b-button> -->
                        </div>
                      </div>

                      <span :class="`${isMobileView ? 'font-small-4 font-weight-bolder' : 'font-medium-2 fw-700'}`">
                        {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)} ${convertISODateTime(segment.departureTime).dayMonth} ${segment.departure}${segment.arrival} ${convertISODateTime(segment.departureTime).hourMin} ${convertISODateTime(segment.arrivalTime).hourMin}` }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </b-overlay>
      </b-card>

      <!-- ANCHOR - Chọn khoảng giá canh vé -->
      <b-card
        header-bg-variant="light-warning"
        header-class="py-1"
        class="mb-75 border"
        body-class="mb-0"
      >
        <template #header>
          <h5
            id="reservation.lowFare.rangeFare"
            class="m-0 text-black font-medium-2 font-weight-bolder"
          >
            2. {{ $t('reservation.lowFare.rangeFare') }}
          </h5>
        </template>

        <div v-if="!isEmpty(listAllTrips)">
          <div
            v-if="['fareWithMinMax'].includes(lowFareType)"
            class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between mt-75"
          >
            <span
              id="reservation.lowFare.lowestFare"
              class="text-nowrap mr-50"
            >
              {{ $t('reservation.lowFare.lowestFare') }}
            </span>
            <IAmInputMoney :value-money.sync="targetFareMin" />
          </div>
          <div class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between mt-1">
            <span class="text-nowrap mr-50">
              {{ $t('reservation.lowFare.highestFare') }} (<span class="text-danger">*{{ $t('reservation.lowFare.required') }}</span>):
            </span>
            <IAmInputMoney :value-money.sync="targetFareMax" />
          </div>
          <div class="font-italic text-warning font-weight-bolder mt-25">
            {{ $t('reservation.lowFare.noteGetPrice') }}
          </div>
        </div>
      </b-card>

      <!-- ANCHOR OPTION: Cài deadline cho canh vé -->
      <b-card
        v-if="!isEmpty(listAllTrips)"
        header-bg-variant="light-warning"
        header-class="py-1"
        class="mb-75 border"
        body-class="py-1 mb-0 px-1 px-md-2"
      >
        <template #header>
          <h5
            id="reservation.lowFare.deadlineTask"
            class="m-0 text-black font-medium-2 font-weight-bolder"
          >
            3. {{ $t('reservation.lowFare.deadlineTask') }}
          </h5>
        </template>

        <div>
          <div>
            <div>
              <div class="button_spacing">
                <div
                  v-for="(time, indexTime) of optionsTimeSelect"
                  :key="indexTime"
                  variant="light-primary"
                  class="time_select_class cursor-pointer py-50 px-75 px-md-2"
                  :class="timeSelected === time.value ? 'active' : '' "
                  @click="handleSetDeadline(time.value)"
                >
                  <span class="font-weight-bolder text-nowrap">
                    {{ time.title }}
                  </span>
                </div>
              </div>

              <div
                v-if="expectTime && !afterMaxDate"
                class="mt-1 font-weight-bolder"
              >
                {{ $t('reservation.lowFare.timeEndTracking') }}
                <span class="text-warning text-nowrap tex-body ml-25">
                  {{ convertISODateTime(deadline).timeDate.replace(" | ", " ") }}
                </span>
              </div>
            </div>

            <div
              v-if="afterMaxDate"
              class="mt-1 text-normal"
            >
              <b-alert
                variant="danger"
                show
                class="py-50 mt-2 mb-0 text-center"
              >
                {{ $t('reservation.lowFare.setTimeBefore') }} {{ convertISODateTime(maxDate).timeDate.replace(" | ", " ") }}
              </b-alert>
            </div>
          </div>
        </div>
      </b-card>

      <!-- ANCHOR: Type canh vé -->
      <b-card
        v-if="!isEmpty(listAllTrips)"
        header-bg-variant="light-warning"
        header-class="py-1"
        class="mb-75 border"
        body-class="py-1 mb-0 px-50 px-md-1"
      >
        <template #header>
          <h5
            id="reservation.lowFare.lowfareType"
            class="m-0 text-black font-medium-2 font-weight-bolder"
          >
            4. {{ $t('reservation.lowFare.lowfareType') }}
          </h5>
        </template>

        <div>
          <b-form-group
            #default="{ ariaDescribedby }"
            label=""
          >
            <b-form-radio-group
              id="radio-group-bookingType"
              v-model="bookingType"
              stacked
              value-field="value"
              text-field="name"
              :disabled="isDisableLowfareType"
              :options="bookingTypeOptions"
              :aria-describedby="ariaDescribedby"
            />
          </b-form-group>
        </div>

        <BAlert
          show
          variant="warning"
          class="text-center fw-700 py-50 mb-50"
        >
          Trường hợp canh ra cùng hạng vé (ví dụ đặt HL/DS để canh HK) , hệ thống sẽ tự động sinh ra code mới.
        </BAlert>
        <BAlert
          show
          variant="danger"
          class="text-center fw-700 py-50 mb-50"
        >
          BGT không chịu trách nhiệm đối với các trường hợp bị hủy code do dupe.
        </BAlert>
      </b-card>

      <!-- ANCHOR - BUTTON bật canh vé -->
      <div
        v-if="!isEmpty(listAllTrips)"
        class="text-center my-2"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="px-2"
          variant="outline-info"
          pill
          :disabled="disableSetLowFareButton(tripSelected, lowFareType, targetFareMin, targetFareMax)"
          @click="handleLowFareTracking('set')"
        >
          <span class="font-medium-1">{{ $t('reservation.lowFare.turnOn') }}</span>
        </b-button>
      </div>

      <!-- ANCHOR - ĐANG BẬT -->
      <b-card
        v-if="refbookingData.lowFareTrackingTasks"
        header-bg-variant="light-warning"
        header-class="py-1"
        class="mb-75 border"
        body-class="px-25 pb-0"
      >
        <template #header>
          <h5 class="m-0 text-black font-medium-2 font-weight-bolder">
            {{ $t('reservation.lowFare.infoLowFare') }}
          </h5>
        </template>

        <div
          v-if="!isEmpty(refbookingData.lowFareTrackingTasks)"
          class="mt-1 mt-md-0"
        >
          <b-table
            outline
            responsive
            bordered
            small
            class="mt-1"
            thead-class="text-center text-dark font-weight-bolder text-nowrap"
            tbody-class="text-center"
            :items="getTrackingTaskItems(refbookingData.lowFareTrackingTasks)"
            :fields="['itinerary', 'information', 'result', 'action']"
          >
            <template
              v-for="column in ['itinerary', 'information', 'result', 'action']"
              #[`head(${column})`]="data"
            >
              <span
                :key="column"
                class="text-dark text-nowrap"
              >
                {{ $t(`reservation.${data.label.toLowerCase()}`) }}
              </span>
            </template>

            <template #cell(itinerary)="{item}">
              <div
                v-for="(segment, indexSegment) of item.payload.segments"
                :key="indexSegment"
                class="d-flex flex-column"
              >
                <div class="text-nowrap">
                  {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)} ${convertISODateTime(segment.departureTime, 'UTC').dayMonth} ${segment.departure}${segment.arrival}` }}
                </div>
                <div class="text-nowrap">
                  {{ `${convertISODateTime(segment.departureTime, 'UTC').hourMin} ${convertISODateTime(segment.arrivalTime, 'UTC').hourMin}` }}
                </div>
              </div>
            </template>
            <template #cell(information)="{item}">
              <div>
                <div class="text-nowrap">
                  Giá thấp nhất:
                </div>
                <div class="text-right font-weight-bolder mb-25">
                  {{ item.payload.minPrice ? formatCurrency(item.payload.minPrice) : 0 }}
                </div>

                <div class="text-nowrap">
                  Giá cao nhất:
                </div>
                <div class="text-right font-weight-bolder">
                  {{ item.payload.maxPrice ? formatCurrency(item.payload.maxPrice) : 0 }}
                </div>
              </div>
            </template>
            <template #cell(result)="{item}">
              <div v-if="!item.result">
                <b-badge
                  v-if="['HOLD'].includes(bookingData.status)"
                  :variant="item.payload.isExpired ? 'danger' : 'primary'"
                  class="badge-glow text-nowrap py-50 px-1 font-small-2"
                >
                  {{ item.payload.isExpired ? $t('reservation.lowFare.expired') : $t('reservation.lowFare.processing') }}
                </b-badge>
                <div
                  v-if="item.payload.deadline"
                  class="text-danger font-italic mt-50"
                >
                  <span class="text-nowrap">
                    {{ $t('reservation.lowFare.timeEndTracking') }}
                  </span>
                  <div>
                    {{ convertISODateTime(item.payload.deadline).timeDate.replace(" | ", " ") }}
                  </div>
                </div>
              </div>

              <b-badge
                v-if="item.result"
                variant="success"
                class="badge-glow text-nowrap py-50 font-small-2"
              >
                {{ $t('reservation.lowFare.success') }}
              </b-badge>
            </template>
            <template #cell(action)="{item}">
              <b-button
                v-if="!item.result"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="py-50 px-50 px-md-1 text-nowrap"
                variant="outline-danger rounded"
                :disabled="['CANCEL'].includes(bookingData.status) || item.payload.isExpired"
                @click="handleLowFareTracking('cancel', item.id)"
              >
                {{ $t('reservation.lowFare.turnOff') }}
              </b-button>

              <div
                v-if="item.result"
                class="w-100 text-center"
              >
                <b-button
                  v-for="(newItem, cIndex) of JSON.parse(item.result).lowFareTrackedBookings"
                  :key="cIndex"
                  variant="outline-info"
                  class="text-nowrap py-25 px-1 font-medium-1 rounded"
                  :class="JSON.parse(item.result).lowFareTrackedBookings.length > 1 ? 'mb-50' : ''"
                  @click="openLinkedBookingHandle(newItem.id)"
                >
                  {{ newItem.pnr }}
                </b-button>
              </div>
            </template>
          </b-table>
        </div>

        <b-alert
          v-else
          variant="warning"
          show
          class="px-1 py-50 mt-1"
        >
          <p class="font-medium-1 font-weight-bolder">
            {{ $t('reservation.lowFare.noItiSelect') }}
          </p>
        </b-alert>
      </b-card>

      <!-- ANCHOR - CHƯA BẬT -->
      <b-card
        v-else
        header-bg-variant="light-warning"
        header-class="py-1"
        body-class="mt-75 pb-1"
        class=" border"
      >
        <template #header>
          <h5 class="m-0 text-black font-medium-2 font-weight-bolder">
            5. {{ $t('reservation.lowFare.infoLowFare') }}
          </h5>
        </template>

        <span class="text-warning font-medium-1 font-weight-bolder">
          {{ $t('reservation.lowFare.notTurnOn') }}
        </span>
      </b-card>
    </div>

    <b-alert
      variant="warning"
      show
      class="px-1 py-50 my-50 font-medium-1"
    >
      <p class="text-airline fw-700">
        {{ $t('reservation.lowFare.note') }}
      </p>
      <p class="text-danger fw-700">
        {{ $t('reservation.lowFare.note1') }}
      </p>
      <!-- <p class="text-airline fw-700">
        {{ $t('reservation.lowFare.note2') }}
      </p> -->
      <p class="text-airline fw-700">
        {{ $t('reservation.lowFare.note3') }}
      </p>
    </b-alert>

    <ModalInfoTrip :segment-info.sync="segmentInfo" />
  </b-modal>
</template>

<script>
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BModal,
  BButton,
  BAlert,
  BCard,
  BTable,
  BBadge,
  BFormGroup,
  BFormRadioGroup,
  BOverlay,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import moment from 'moment'

import router from '@/router'

import { trimUpperCaseInput } from '@core/comp-functions/forms/formatter-input'
import {
  convertShortTrip, convertISODateTime, formatCurrency, resolveAirlineFlightNumber, checkTimeExpired,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

import 'moment/locale/vi'
import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BAlert,
    BCard,
    BBadge,
    BTable,
    BFormGroup,
    BFormRadioGroup,
    BOverlay,

    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    ModalInfoTrip: () => import('@flightv2/ModalInfoTrip.vue'),
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    /*
    1. Chọn từng hành hành trình để canh vé.
    2. Disable những hành trình đã sử dụng hoặc những hành trình đã canh thành công
    3. Tự fill giá sau khi chọn hành trình
    4. Mỗi code vé không được canh nhiều hành trình 1 lúc.
    */
    const { toastError } = useToast()

    const {
      setLowFareTracking,
      cancelLowFareTracking,
      getFareRulesAirlineByBookingClass,
    } = useReservationHandle()

    const {
      getClassBookingInfoTrip,
    } = useBookingHandle()

    // Trừ một khoản tiền từ NetFare để đặt default
    const SUB_AMOUNT_TO_SET_DEFAULT_LOW_FARE = 1000

    const refbookingData = toRefs(props).bookingData
    const listAllTrips = ref([])
    const tripSelected = ref([])

    const isDisableLowfareType = computed(() => false)

    const lowFareType = ref('fareWithMinMax')
    const targetFareMin = ref(0) // giá thấp nhất
    const targetFareMax = ref(0) // giá cao nhất
    const bookingType = ref()

    const bookingTypeOptions = [
      {
        name: 'Tạo booking mới',
        value: 'CREATE_BOOKING',
      },
      // {
      //   name: 'Thêm hành trình vào booking hiện tại',
      //   value: 'ADD_ITINERARY',
      // },
    ]

    const hasSetDeadline = ref(false)

    const getDefaultDeadline = (H = 6) => {
      const now = new Date()
      now.setHours(now.getHours() + H)
      return now.toISOString()
    }

    const deadline = ref(getDefaultDeadline())

    const optionsTimeSelect = [
      {
        title: '3 giờ',
        value: 3,
      },
      {
        title: '6 giờ',
        value: 6,
      },
      {
        title: '9 giờ',
        value: 9,
      },
      {
        title: '12 giờ',
        value: 12,
      },
    ]

    const timeSelected = ref(6)

    const handleSetDeadline = hour => {
      timeSelected.value = hour
      deadline.value = getDefaultDeadline(hour)
    }
    const currentTime = new Date().toISOString()
    const expectTime = computed(() => {
      if (deadline.value) {
        const deadlineTime = deadline.value
        const timeDiff = moment(deadlineTime).diff(moment(currentTime))
        moment.locale('vi')
        const humanReadableString = moment.duration(timeDiff).humanize()
        return `${humanReadableString}`
      }
      return null
    })

    const getMinDate = () => new Date()
    const minDate = ref(getMinDate())

    // Thời gian bay
    const getMaxDate = () => {
      const timeDeparture = [moment(refbookingData.value.bookingRequest.itineraries[0].segments[0].departureTime)]
      const result = timeDeparture.sort((a, b) => moment(a).isAfter(moment(b)))[0].toISOString()
      return result
    }

    const maxDate = ref(getMaxDate())
    const afterMaxDate = ref(false)

    // Nếu thay đổi deadline thì đặt lại warning
    watch(deadline, () => {
      afterMaxDate.value = false
    })

    function disableSetLowFareButton(tripSelected, lowFareType, targetFareMin, targetFareMax) {
      if (isEmpty(tripSelected)) return true
      if ((targetFareMax === 0 || !targetFareMax)) return true
      if (hasSetDeadline.value && !deadline.value) return true

      return false
    }

    const hasTaskRunning = computed(() => {
      let result = false
      if (!refbookingData.value.lowFareTrackingTasks || isEmpty(refbookingData.value.lowFareTrackingTasks)) return false

      refbookingData.value.lowFareTrackingTasks.forEach(task => {
        const data = JSON.parse(task.payload)
        const isExpired = checkTimeExpired(data.deadline) || !!task.result // check những task đang thực hiện canh vé, loại trừ quá hạn hoặc canh thành công
        if (!isExpired) {
          result = true
        }
      })
      return result
    })

    // resolve list index những mã đặt chỗ đã canh vé thành công
    const arrIndexTripSuccess = computed(() => {
      const result = []
      const listTaskDone = refbookingData.value?.lowFareTrackingTasks?.filter(t => !!t.result)

      if (!refbookingData.value.lowFareTrackingTasks || isEmpty(listTaskDone)) return []

      listTaskDone.forEach(task => {
        const data = JSON.parse(task.payload)
        const bookingRequestTrips = refbookingData.value?.bookingRequest?.itineraries
        const successSeg0 = data?.itineraries[0]
        const index = bookingRequestTrips.findIndex(trip => isEqual(trip.segments, successSeg0.segments))

        if (index !== -1) {
          result.push(index)
        }
      })

      return result
    })

    function handleSelectedTrip(trip) {
      const indexTripInArr = tripSelected.value.findIndex(t => t.index === trip.index)
      const tripToPush = cloneDeep(trip)

      if ((indexTripInArr === -1) && isEmpty(tripSelected.value)) {
        targetFareMax.value = tripToPush.targetFareMax > SUB_AMOUNT_TO_SET_DEFAULT_LOW_FARE
          ? tripToPush.targetFareMax - SUB_AMOUNT_TO_SET_DEFAULT_LOW_FARE : 0
        tripSelected.value.push(tripToPush)
      } else if ((indexTripInArr === -1) && !isEmpty(tripSelected.value)) {
        targetFareMax.value = tripToPush.targetFareMax > SUB_AMOUNT_TO_SET_DEFAULT_LOW_FARE
          ? tripToPush.targetFareMax - SUB_AMOUNT_TO_SET_DEFAULT_LOW_FARE : 0
        tripSelected.value.splice(0, 1, tripToPush)
      } else {
        tripSelected.value.splice(indexTripInArr, 1)
      }
    }

    const getTrackingTaskItems = taskArr => {
      const getPayload = taskArr.map(task => {
        const parsePayload = JSON.parse(task.payload)
        return {
          ...task,
          payload: {
            segments: parsePayload?.itineraries[0]?.segments?.map(s => s),
            minPrice: parsePayload.targetFareAmountBottom || 0,
            maxPrice: parsePayload.targetFareAmount || 0,
            deadline: parsePayload.deadline || null,
            isExpired: parsePayload.deadline ? checkTimeExpired(parsePayload.deadline) : true,
          },
        }
      })
      return getPayload
    }

    function handleLowFareTracking(action, id) {
      if (hasSetDeadline.value) {
        afterMaxDate.value = moment(deadline.value).isAfter(maxDate.value)

        if (afterMaxDate.value) {
          const errTitle = `Vui lòng nhập thời gian trước ${convertISODateTime(maxDate).timeDate.replace(' | ', ' ')}`
          toastError({ title: errTitle })
          return
        }
      }

      const wrapPayloadToSet = cloneDeep(tripSelected.value).map(t => {
        const getFareAmount = listAllTrips.value.find(trip => trip.index === t.index)
        if (!getFareAmount) {
          toastError({ title: 'Lỗi lấy giá hành trình, vui lòng thực hiện lại!' })
          return
        }

        if (arrIndexTripSuccess.value.includes(t.index)) {
          toastError({ title: 'Hành trình lựa chọn đã được canh vé thành công!' })
          return
        }

        // eslint-disable-next-line consistent-return
        return {
          index: t.index,
          targetFareAmount: targetFareMax.value,
          targetFareAmountBottom: targetFareMin.value ? targetFareMin.value : 0,
          deadline: deadline.value ? convertISODateTime(deadline.value).ISOdatetime : null,
          // deadline: '2024-03-04T14:37:31+07:00', // test + 1p
          bookingType: bookingType.value,
        }
      })

      if (action === 'set') {
        if (hasTaskRunning.value) {
          toastError({ title: 'Đã có hành trình đang được thực hiện canh vé, không thể tạo thêm!' })
          return
        }

        const isValidParams = wrapPayloadToSet.some(t => t.targetFareAmount)
        if (!isValidParams) {
          toastError({ title: 'Vui lòng nhập giá mong muốn hoặc chọn hạng vé cho từng hành trình đã chọn!' })
          return
        }
      }

      const wrapPayloadToCancel = {
        lowFareTrackingTaskIds: [id],
      }

      // const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: `Xác nhận ${action === 'set' ? 'bật tính năng Canh vé' : 'tắt tính năng Canh vé trên hành trình này'} ?` } })

      // const titleMsgs = this.$createElement('div', { class: `fw-700 text-${(action === 'set') ? 'danger' : 'warning'} font-medium-3` },
      //   `${(action === 'set') ? 'Huỷ' : 'Hoàn'} gói bảo hiểm`)

      const bodyMsg = action => this.$createElement('div', { class: 'fw-600' }, [
        `Xác nhận ${(action === 'set') ? 'bật canh vé ?' : 'tắt canh vé trên hành trình này ?'}`,
        this.$createElement('div', { class: 'mt-1' }, [
          this.$createElement('p', { class: 'text-danger fw-700 mb-25' }, 'Lưu ý quan trọng: '),
          this.$createElement('span', { class: 'text-danger fw-700' },
            `${(action === 'set') ? '- Vui lòng hủy code cũ sau khi đã canh vé thành công càng sớm càng tốt (lưu ý kiểm tra lại giá và điều kiện vé), tất cả code bị dupe có thể bị huỷ trước thời gian cảnh báo. BGT không chịu trách nhiệm đối với các trường hợp bị hủy code do dupe.' : ''}`),
        ]),
      ])

      this.$bvModal
        .msgBoxConfirm(bodyMsg(action), {
          title: this.$t('modal.confirm'),
          size: 'md',
          okVariant: `${action === 'set' ? 'success rounded-pill' : 'danger rounded-pill'}`,
          okTitle: this.$t('modal.confirm'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary rounded-pill',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            if (action === 'set') {
              setLowFareTracking({ id: refbookingData.value.id, payload: { targets: wrapPayloadToSet } })
                .then(() => {
                  tripSelected.value = []
                })
                .finally(() => {
                  this.$bvModal.hide('modal-api-loading')
                })
            } else {
              cancelLowFareTracking({ id: refbookingData.value.id, payload: wrapPayloadToCancel })
                .then(() => {
                  // this.$bvModal.hide('modal-modify-low-fare-tracking')
                })
                .finally(() => {
                  this.$bvModal.hide('modal-api-loading')
                })
            }
          }
        })
    }

    function showModal() {
      bookingType.value = 'CREATE_BOOKING' // FIXME : VU, VN1A sẽ mặc định chỉ tạo booking mới
      tripSelected.value = []
      targetFareMax.value = 0

      listAllTrips.value = refbookingData.value.bookingRequest.itineraries.map((trip, indexTrip) => ({
        index: indexTrip,
        airline: trip.airline,
        flights: `${trip.segments[0].departure}-${trip.segments[trip.segments.length - 1].arrival}`,
        tripInfo: trip.segments,
        targetFareMax: trip?.fareBreakdowns?.find(fare => fare.paxType === 'ADULT')?.total || null,
      }))
    }

    function openLinkedBookingHandle(id) {
      router
        .push({ name: 'apps-reservations-modify', params: { id } })
        .then(() => {
          this.$bvModal.hide('modal-modify-low-fare-tracking')
        })
        .catch(() => { })
    }

    const segmentInfo = ref([])
    function handleShowModalInfoTrip(segment) {
      if (!segment.bookingClassId) {
        toastError({
          title: 'Thiếu dữ liệu thông tin hành trình!',
          content: 'Mã đặt chỗ hiện tại thiếu thông tin để thực hiện, vui lòng đặt booking mới để có thể xem thông tin.',
        })
        return
      }

      segmentInfo.value = []
      this.$bvModal.show('modal-api-loading')
      try {
        const payload = {
          classBookingId: segment.bookingClassId,
        }
        const payloadSearch = {
          flights: [
            {
              startPoint: segment.departure,
              endPoint: segment.arrival,
            },
          ],
        }
        const tripData = {
          segmentIndex: 0,
          legsTrip: 1,
          airline: ['1S', '1S_CTRL'].includes(refbookingData.value.source) ? 'VN' : refbookingData.value.source,
        }
        getClassBookingInfoTrip(payload, payloadSearch, tripData)
          .then(res => {
            segmentInfo.value = res
            this.$bvModal.show('modal-flightv2-show-info-trip')
          })
          .catch(err => {
            toastError({
              title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!',
            })
            console.error({ err })
          })
          .finally(() => {
            this.$bvModal.hide('modal-api-loading')
          })
      } catch (error) {
        console.error({ error })
        toastError({
          title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!',
        })
      }
    }

    return {
      refbookingData,
      tripSelected,
      listAllTrips,
      minDate,
      maxDate,
      deadline,
      optionsTimeSelect,
      handleSetDeadline,
      bookingType,
      bookingTypeOptions,
      isDisableLowfareType,

      isEmpty,
      convertShortTrip,
      hasTaskRunning,
      arrIndexTripSuccess,
      showModal,
      getFareRulesAirlineByBookingClass,
      handleLowFareTracking,
      handleSelectedTrip,
      trimUpperCaseInput,

      openLinkedBookingHandle,
      handleShowModalInfoTrip,
      segmentInfo,
      convertISODateTime,

      lowFareType,
      targetFareMax,
      targetFareMin,
      disableSetLowFareButton,
      hasSetDeadline,
      afterMaxDate,

      expectTime,
      timeSelected,
      getTrackingTaskItems,
      formatCurrency,

      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.time_select_class{
  border: 2px solid #ebebeb;
  border-radius: 10px;

  &.active {
      border: 2px solid #62c8ff;
      background-color: #dff4ff;
      box-shadow: 0 1px 10px rgba(144, 216, 255, 0.726);
  }
}

.button_spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.1rem;
  }

  @media (max-width: 440px) {
    justify-content: space-between;
    & > * {
      margin-right: 0;
    }
  }
}

#radio-group-bookingType::v-deep {
  .custom-control.custom-radio {
    margin-bottom: 12px;

    .custom-control-label {
      margin-top: 0px;
      font-size: 16px;
    }
  }
}
</style>
